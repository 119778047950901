import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  viewBox: PropTypes.string,
  width: PropTypes.number,
}

const defaultProps = {
  className: 'menu__icon',
  viewBox: '0 0 56 25',
  width: 56,
  height: 25,
}

/**
 * SVG Sofort Icon
 */
const IconSofort = (props) => {
  const { height, viewBox, width } = props
  return (
    <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M41.6667 21.6667H0L3.16667 3.33334H45L41.6667 21.6667Z' fill='#393A41' />
      <g clip-path='url(#clip0_9:576)'>
        <path
          d='M13.8211 9.66667C11.9491 9.66667 10.528 11.0758 10.528 12.7549C10.528 14.274 11.6943 15.3333 13.2821 15.3333C15.1541 15.3333 16.5654 13.8842 16.5654 12.2052C16.5752 10.6761 15.4089 9.66667 13.8211 9.66667ZM13.3997 13.9042C12.6842 13.9042 12.1844 13.4245 12.1844 12.6849C12.1844 11.8654 12.8018 11.0858 13.6937 11.0858C14.4092 11.0858 14.9091 11.5855 14.9091 12.3251C14.9189 13.1546 14.2916 13.9042 13.3997 13.9042Z'
          fill='#EDEDED'
        />
        <path
          d='M25.0825 9.66667C23.2105 9.66667 21.7992 11.0758 21.7992 12.7549C21.7992 14.274 22.9655 15.3333 24.5533 15.3333C26.4253 15.3333 27.8366 13.8842 27.8366 12.2052C27.8464 10.6761 26.6801 9.66667 25.0825 9.66667ZM24.6709 13.9042C23.9554 13.9042 23.4556 13.4245 23.4556 12.6849C23.4556 11.8654 24.073 11.0858 24.9649 11.0858C25.6804 11.0858 26.1802 11.5855 26.1802 12.3251C26.1802 13.1546 25.5628 13.9042 24.6709 13.9042Z'
          fill='#EDEDED'
        />
        <path
          d='M33.3448 11.4556C33.3448 10.4362 32.5705 9.78661 31.1592 9.78661H29.1794L28.3071 15.2134H29.9732L30.2477 13.5044H30.3261L31.2082 15.2134H33.1194L31.8942 13.3145C32.8057 12.9947 33.3448 12.3051 33.3448 11.4556ZM30.6789 12.3551H30.4339L30.6201 11.2158H30.8455C31.365 11.2158 31.6394 11.3757 31.6394 11.7255C31.6394 12.1352 31.2474 12.3551 30.6789 12.3551Z'
          fill='#EDEDED'
        />
        <path
          d='M8.61678 11.9553C7.97971 11.6255 7.8425 11.5456 7.8425 11.3657C7.8425 11.1458 8.09733 11.0359 8.44036 11.0359C8.8422 11.0359 9.44987 11.0858 10.0477 11.6655C10.1947 11.1958 10.4398 10.776 10.7534 10.4062C9.91052 9.91652 9.12643 9.66667 8.41096 9.66667C6.98981 9.66667 6.16652 10.4462 6.16652 11.3857C6.16652 12.1952 6.76438 12.6149 7.47006 12.9847C8.10713 13.3145 8.26394 13.4245 8.26394 13.6243C8.26394 13.8442 7.99932 13.9641 7.64648 13.9641C7.03881 13.9641 6.33314 13.5744 5.91169 13.1746L5.66667 14.6837C6.09791 15.0035 6.72518 15.3333 7.67588 15.3333C9.14604 15.3333 9.93992 14.5738 9.93992 13.6044C9.93992 12.8048 9.43027 12.3751 8.61678 11.9553Z'
          fill='#EDEDED'
        />
        <path
          d='M20.9661 12.1652H19.0059L19.1627 11.2158H21.2601C21.5345 10.6461 21.956 10.1564 22.4754 9.78661H19.0451C18.2806 9.78661 17.6337 10.3163 17.5259 11.0359L16.8595 15.2234H18.5256L18.8001 13.5144H20.7799L20.9269 12.585C20.9367 12.4351 20.9465 12.3051 20.9661 12.1652Z'
          fill='#EDEDED'
        />
        <path
          d='M33.384 9.78661C33.8349 10.1364 34.1093 10.6261 34.1681 11.2158H35.5598L34.9228 15.2134H36.5889L37.226 11.2158H38.7746L39 9.78661H33.384Z'
          fill='#EDEDED'
        />
      </g>
      <defs>
        <clipPath id='clip0_9:576'>
          <rect width='33.3333' height='5.66667' fill='white' transform='translate(5.66667 9.66667)' />
        </clipPath>
      </defs>
    </svg>
  )
}

IconSofort.displayName = 'IconSofort'
IconSofort.propTypes = propTypes
IconSofort.defaultProps = defaultProps

export default IconSofort
