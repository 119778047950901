import React, { Fragment, useEffect } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'

import { createId } from '@elo-kit/utils/general.utils'

import Filter from 'ui/filter/Filter'

import './top-bar.scss'
import '../../../../src/styles/react/ui/section-tabs.scss'

interface TopBarProps {
  activeTab: string
  setActiveTab: (key: string) => void
  defaultTab: string
  tabs: { key: string; label: string; count?: null | number }[]
  sectionClasses?: string
  sectionTabsClassName?: string
  sectionTabsContentClassName?: string
  filtersList: any
  filterStores?: any
  initStore: any
  timezone?: string
}

export const TopBarTabs: React.FC<TopBarProps> = observer(
  ({
    activeTab,
    setActiveTab,
    defaultTab,
    tabs,
    sectionTabsClassName,
    sectionTabsContentClassName,
    filtersList,
    filterStores,
    initStore,
    timezone,
  }) => {
    useEffect(() => {
      setActiveTab(defaultTab)
    }, [])

    const sectionTabsClasses = classNames('elo-section-tabs', sectionTabsClassName)

    return (
      <Fragment>
        <ul className={sectionTabsClasses}>
          <div className='top-bar__filter'>
            {filtersList && activeTab === 'notifications' && (
              <Fragment>
                <Filter
                  fetchOptionsStores={filterStores}
                  {...{
                    filtersList,
                    initStore,
                    timezone,
                  }}
                />
              </Fragment>
            )}
          </div>
          <div className='elo-section-tabs__tabs-wrapper'>
            {tabs.map((tab, index) => {
              const { key, label, count } = tab
              const tabClasses = classNames(
                'elo-section-tabs__tab-content',
                {
                  'elo-section-tabs__tab-content--active': activeTab === key,
                },
                sectionTabsContentClassName
              )
              const liClasses = classNames('elo-section-tabs__tab', {
                'elo-section-tabs__tab--active': activeTab === key,
              })

              return (
                <li className={liClasses} key={createId(key, index)}>
                  <div className={tabClasses} onClick={() => setActiveTab(key)}>
                    {label}
                    {count > 0 && <div className='notifics-count in-tabs'>{count}</div>}
                  </div>
                </li>
              )
            })}
          </div>
        </ul>
      </Fragment>
    )
  }
)
