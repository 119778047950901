import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  viewbox: PropTypes.string,
  width: PropTypes.number,
}

const defaultProps = {
  className: 'menu__icon',
  viewbox: '0 0 58 14',
  width: 58,
  height: 14,
}

/**
 * SVG Paypal Icon
 */
const IconPaypal = (props) => {
  const { height, viewBox, width } = props
  return (
    <svg width={width} height={height} viewBox={viewBox}>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='Product-creation-Checkout-page2-One-time-payment-Plan-edit'
          transform='translate(-420.000000, -405.000000)'
          fillRule='nonzero'
        >
          <g id='-PayPal' transform='translate(420.000000, 405.000000)'>
            <path
              d='M21.8814815,6.09259259 C21.7129629,7.23333331 20.8444445,7.23333331 20.0018519,7.23333331 L19.5222222,7.23333331 L19.8592593,5.10740741 C19.8851852,4.97777778 19.9888889,4.88703704 20.1185185,4.88703704 L20.3388889,4.88703704 C20.9092593,4.88703704 21.4537037,4.88703704 21.7259259,5.21111111 C21.8944445,5.40555555 21.9462963,5.69074074 21.8814815,6.09259259 Z M21.5185185,3.12407407 L18.3555555,3.12407407 C18.1351852,3.12407407 17.9537037,3.27962963 17.9148148,3.5 L16.6314815,11.6148148 C16.6055555,11.7703704 16.7351852,11.9129629 16.8907407,11.9129629 L18.4074074,11.9129629 C18.6277778,11.9129629 18.8092593,11.7574074 18.8481481,11.5370371 L19.1981481,9.34629633 C19.237037,9.1259259 19.4185185,8.97037036 19.6259259,8.97037036 L20.6240741,8.97037036 C22.7111111,8.97037036 23.9166667,7.95925928 24.2277778,5.96296296 C24.3703704,5.09444445 24.2277778,4.40740741 23.8259259,3.91481481 C23.3722222,3.40925926 22.5685185,3.12407407 21.5185185,3.12407407 Z'
              id='XMLID_57_'
              fill='#253B80'
            />
            <path
              d='M28.8685185,8.99629633 C28.7259259,9.86481482 28.0388889,10.4481481 27.1574074,10.4481481 C26.7166667,10.4481481 26.3666667,10.3055555 26.1333333,10.0333333 C25.9129629,9.7740741 25.8222222,9.38518518 25.9,8.97037036 C26.0425926,8.11481482 26.7296296,7.50555554 27.5981481,7.50555554 C28.0259259,7.50555554 28.3759259,7.64814813 28.6092593,7.92037036 C28.8425926,8.20555554 28.9333333,8.58148151 28.8685185,8.99629633 Z M30.9814815,6.05370371 L29.4648148,6.05370371 C29.3351852,6.05370371 29.2185185,6.14444445 29.2055555,6.27407407 L29.1407407,6.70185185 L29.037037,6.54629629 C28.7129629,6.06666667 27.9740741,5.91111111 27.2481481,5.91111111 C25.5759259,5.91111111 24.137037,7.18148151 23.8648148,8.95740741 C23.7222222,9.83888892 23.9296296,10.6944445 24.4351852,11.2907407 C24.9018519,11.8351852 25.5629629,12.0555555 26.3407407,12.0555555 C27.6888889,12.0555555 28.4407407,11.187037 28.4407407,11.187037 L28.3759259,11.6018519 C28.35,11.7574074 28.4796296,11.9129629 28.6351852,11.9129629 L29.9962963,11.9129629 C30.2166667,11.9129629 30.3981481,11.7574074 30.437037,11.537037 L31.2537037,6.35185185 C31.2666667,6.19629629 31.137037,6.05370371 30.9814815,6.05370371 Z'
              id='XMLID_54_'
              fill='#253B80'
            />
            <path
              d='M39.0444445,6.05370371 L37.5277778,6.05370371 C37.3851852,6.05370371 37.2425926,6.13148148 37.1648148,6.24814815 L35.0648148,9.33333331 L34.1703704,6.36481481 C34.1185185,6.18333333 33.937037,6.05370371 33.7425926,6.05370371 L32.2518519,6.05370371 C32.0703704,6.05370371 31.9407407,6.23518519 32.0055555,6.40370371 L33.6777778,11.3296296 L32.0962963,13.5592593 C31.9666667,13.7407407 32.0962963,13.9740741 32.3166667,13.9740741 L33.8333333,13.9740741 C33.9759259,13.9740741 34.1185185,13.9092593 34.1962963,13.7796296 L39.2648148,6.46851852 C39.3814815,6.28703704 39.2648148,6.05370371 39.0444445,6.05370371 Z'
              id='XMLID_33_'
              fill='#253B80'
            />
            <path
              d='M44.45,6.09259259 C44.2814815,7.23333331 43.4129629,7.23333331 42.5703704,7.23333331 L42.0907407,7.23333331 L42.4277778,5.10740741 C42.4537037,4.97777778 42.5574074,4.88703704 42.687037,4.88703704 L42.9074074,4.88703704 C43.4777778,4.88703704 44.0222222,4.88703704 44.2944445,5.21111111 C44.4629629,5.40555555 44.5148148,5.69074074 44.45,6.09259259 Z M44.087037,3.12407407 L40.9240741,3.12407407 C40.7037037,3.12407407 40.5222222,3.27962963 40.4962963,3.5 L39.2129629,11.6148148 C39.187037,11.7703704 39.3166667,11.9129629 39.4722222,11.9129629 L41.0925926,11.9129629 C41.2481481,11.9129629 41.3777778,11.8092593 41.3907407,11.6537037 L41.7537037,9.35925928 C41.7925926,9.13888892 41.9740741,8.98333331 42.1814815,8.98333331 L43.1796296,8.98333331 C45.2666667,8.98333331 46.4722222,7.97222223 46.7833333,5.97592593 C46.9259259,5.10740741 46.7833333,4.42037037 46.3814815,3.92777778 C45.9537037,3.40925926 45.15,3.12407407 44.087037,3.12407407 Z'
              id='XMLID_38_'
              fill='#179BD7'
            />
            <path
              d='M51.437037,8.99629633 C51.2944445,9.86481482 50.6074074,10.4481481 49.7259259,10.4481481 C49.2851852,10.4481481 48.9351852,10.3055555 48.7018519,10.0333333 C48.4814815,9.7740741 48.3907407,9.38518518 48.4685185,8.97037036 C48.6111111,8.11481482 49.3111111,7.51851849 50.1666667,7.51851849 C50.5944445,7.51851849 50.9444445,7.66111108 51.1777778,7.93333331 C51.4111111,8.20555554 51.5018519,8.58148151 51.437037,8.99629633 Z M53.55,6.05370371 L52.0333333,6.05370371 C51.9037037,6.05370371 51.787037,6.14444445 51.7740741,6.27407407 L51.7092593,6.70185185 L51.6055555,6.54629629 C51.2814815,6.06666667 50.5425926,5.91111111 49.8166667,5.91111111 C48.1444445,5.91111111 46.7055555,7.18148151 46.4333333,8.95740741 C46.2907407,9.83888892 46.4981481,10.6944445 47.0037037,11.2907407 C47.4703704,11.8351852 48.1314815,12.0555555 48.9092593,12.0555555 C50.2574074,12.0555555 51.0092593,11.187037 51.0092593,11.187037 L50.9444445,11.6018519 C50.9185185,11.7574074 51.0481481,11.9129629 51.2037037,11.9129629 L52.5648148,11.9129629 C52.7851852,11.9129629 52.9666667,11.7574074 52.9925926,11.537037 L53.8092593,6.35185185 C53.8351852,6.19629629 53.7185185,6.05370371 53.55,6.05370371 Z'
              id='XMLID_21_'
              fill='#179BD7'
            />
            <path
              d='M55.3388889,3.34444445 L54.0425926,11.6148148 C54.0166667,11.7703704 54.1462963,11.912963 54.3018519,11.912963 L55.6111111,11.912963 C55.8314815,11.912963 56.0129629,11.7574074 56.0518519,11.5370371 L57.3351852,3.42222222 C57.3611111,3.26666667 57.2314815,3.11111111 57.0759259,3.11111111 L55.6111111,3.11111111 C55.4685185,3.12407407 55.3518519,3.21481481 55.3388889,3.34444445 Z'
              id='XMLID_28_'
              fill='#179BD7'
            />
            <path
              d='M3.48703704,13.4944445 L3.73333333,11.9518519 L3.18888889,11.9388889 L0.622222222,11.9388889 L2.41111111,0.596296296 C2.41111111,0.557407407 2.43703704,0.531481481 2.46296296,0.505555555 C2.48888889,0.479629629 2.52777778,0.466666667 2.55370371,0.466666667 L6.89629629,0.466666667 C8.33518518,0.479629629 9.33333331,0.777777777 9.85185187,1.36111111 C10.0981481,1.63333333 10.2537037,1.93148148 10.3314815,2.24259259 C10.4092593,2.57962963 10.4092593,2.98148148 10.3314815,3.46111111 L10.3314815,3.5 L10.3314815,3.81111111 L10.5777778,3.95370371 C10.7851852,4.05740741 10.9407407,4.18703704 11.0703704,4.32962963 C11.2777778,4.56296296 11.4074074,4.87407407 11.4722222,5.22407407 C11.5370371,5.58703704 11.5111111,6.02777778 11.4203704,6.52037037 C11.3037037,7.09074072 11.1351852,7.58333331 10.8888889,7.99814813 C10.6685185,8.3740741 10.3833333,8.68518518 10.0462963,8.91851849 C9.72222223,9.15185187 9.34629633,9.32037036 8.90555554,9.43703705 C8.49074072,9.54074072 8.01111108,9.60555554 7.47962964,9.60555554 L7.12962964,9.60555554 C6.88333333,9.60555554 6.65,9.69629633 6.46851852,9.85185187 C6.28703704,10.0074074 6.15740741,10.2277778 6.11851852,10.4611111 L6.09259259,10.6037037 L5.66481481,13.312963 L5.65185185,13.4166667 C5.65185185,13.4425926 5.63888889,13.4685185 5.62592593,13.4685185 C5.61296296,13.4814815 5.6,13.4814815 5.58703704,13.4814815 L3.48703704,13.4814815 L3.48703704,13.4944445 Z'
              id='XMLID_27_'
              fill='#253B80'
            />
            <path
              d='M10.7981481,3.55185185 C10.7851852,3.62962963 10.7722222,3.72037037 10.7592593,3.81111111 C10.1759259,6.74074074 8.21851849,7.75185187 5.71666667,7.75185187 L4.44629629,7.75185187 C4.13518519,7.75185187 3.88888889,7.97222223 3.83703704,8.27037036 L3.18888889,12.4055555 L3.00740741,13.5722222 C2.98148148,13.7666667 3.12407407,13.9481481 3.33148148,13.9481481 L5.57407407,13.9481481 C5.84629629,13.9481481 6.06666667,13.7537037 6.10555555,13.4944445 L6.13148148,13.3777778 L6.55925926,10.6814815 L6.58518519,10.5388889 C6.62407407,10.2796296 6.85740741,10.0851852 7.11666669,10.0851852 L7.45370367,10.0851852 C9.64444446,10.0851852 11.3555555,9.19074072 11.8611111,6.62407407 C12.0685185,5.54814815 11.9648148,4.6537037 11.4074074,4.01851852 C11.2518519,3.83703704 11.0444445,3.68148148 10.7981481,3.55185185 Z'
              id='XMLID_26_'
              fill='#179BD7'
            />
            <path
              d='M10.2018519,3.30555555 C10.1111111,3.27962963 10.0203704,3.2537037 9.92962964,3.24074074 C9.83888892,3.21481481 9.73518518,3.20185185 9.64444446,3.18888889 C9.30740741,3.13703704 8.91851849,3.11111111 8.51666669,3.11111111 L5.12037037,3.11111111 C5.04259259,3.11111111 4.95185185,3.12407407 4.88703704,3.16296296 C4.73148148,3.24074074 4.61481481,3.38333333 4.58888889,3.56481481 L3.86296296,8.15370367 L3.83703704,8.28333331 C3.88888889,7.98518518 4.14814815,7.76481482 4.44629629,7.76481482 L5.71666667,7.76481482 C8.21851849,7.76481482 10.1759259,6.7537037 10.7462963,3.81111111 C10.7592593,3.72037037 10.7722222,3.64259259 10.7851852,3.55185185 C10.6425926,3.47407407 10.487037,3.40925926 10.3185185,3.35740741 C10.2796296,3.33148148 10.2407407,3.31851852 10.2018519,3.30555555 Z'
              id='XMLID_25_'
              fill='#222D65'
            />
            <path
              d='M4.57592593,3.56481481 C4.60185185,3.38333333 4.71851852,3.24074074 4.87407407,3.16296296 C4.95185185,3.12407407 5.02962963,3.11111111 5.10740741,3.11111111 L8.50370367,3.11111111 C8.90555554,3.11111111 9.28148151,3.13703704 9.63148151,3.18888889 C9.73518518,3.20185185 9.8259259,3.22777778 9.91666669,3.24074074 C10.0074074,3.26666667 10.0981481,3.27962963 10.1888889,3.30555555 C10.2277778,3.31851852 10.2796296,3.33148148 10.3185185,3.34444445 C10.487037,3.39629629 10.6425926,3.46111111 10.7851852,3.53888889 C10.9537037,2.45 10.7851852,1.71111111 10.2018519,1.05 C9.55370367,0.311111111 8.38703705,0 6.89629629,0 L2.5537037,0 C2.24259259,0 1.98333333,0.220370371 1.94444445,0.518518519 L0.12962963,11.9907407 C0.090740741,12.2111111 0.272222222,12.4185185 0.492592593,12.4185185 L3.17592593,12.4185185 L3.85,8.15370367 L4.57592593,3.56481481 Z'
              id='XMLID_24_'
              fill='#253B80'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

IconPaypal.displayName = 'IconPaypal'
IconPaypal.propTypes = propTypes
IconPaypal.defaultProps = defaultProps

export default IconPaypal
