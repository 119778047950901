import React, { Fragment, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap'
import classNames from 'classnames'

import { MyNotifications } from 'ui/top-bar/MyNotifications'

import { EloBellIcon } from '@elo-ui/components/icons/regular'
import { CustomTooltip } from '@elo-kit/components/elo-ui/custom-tooltip/CustomTooltip'
import { POSITIONS } from '@elo-kit/constants/general.constants'
import { ACTIVE_PROFILES } from 'constants/profile.constants'
import { NOTIFICATIONS_FILTER_LIST } from 'constants/notifications.constants'
import { NOTIFICATION_ITEMS_PER_PAGE, PAGINATION_KEYS } from 'constants/pagination.constants'

import { debounceEvent } from 'utils/helpers.utils'

import { TopBarTabs } from './TobBarTabs'

import './top-bar.scss'

const NOTIFICATION_TABS_KEYS = {
  notifications: 'notifications',
}

const TAB_TITLES = [
  {
    key: NOTIFICATION_TABS_KEYS.notifications,
    label: I18n.t('react.shared.notifications.my_notifications'),
    count: null,
  },
]

interface NotificationsMenuProps {
  counts: Map<string, number>
  data: {
    currenciesStore?: any
    notificationsStore: any
    profileType: string
  }
  children?: React.ReactNode
}

export const NotificationsMenu: React.FC<NotificationsMenuProps> = observer(({ counts, data, children }) => {
  const { notificationsStore, profileType } = data || {}
  const totalCount = counts?.get('totalCount') || 0

  const [popoverOpen, setPopoverOpen] = useState(false)
  const [activeTab, setActiveTab] = useState(NOTIFICATION_TABS_KEYS.notifications)
  const isSeller = profileType === ACTIVE_PROFILES.seller

  useEffect(() => {
    const handleScroll = () => popoverOpen && togglePopover()
    debounceEvent(document.addEventListener('scroll', handleScroll), 100)

    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [popoverOpen])

  useEffect(() => {
    setActiveTab(NOTIFICATION_TABS_KEYS.notifications)

    if (notificationsStore) {
      notificationsStore.setExpands([
        'notific_type',
        'notificable.lesson',
        'notificable.lesson.product',
        'sender',
        'seller',
        'sender.user',
        'sender.user_profile',
      ])
      notificationsStore.handleScopeChange('platform', 'web')

      return () => notificationsStore.resetList()
    }
  }, [])

  useEffect(() => {
    if (notificationsStore && popoverOpen) {
      notificationsStore.fetchUnreadCount()
      notificationsStore.handlePaginationChange(PAGINATION_KEYS.per, NOTIFICATION_ITEMS_PER_PAGE)
    }

    return () => {
      popoverOpen && notificationsStore?.fetchUnreadCount()
    }
  }, [popoverOpen])

  const toggleActiveTab = (name) => setActiveTab(name)
  const togglePopover = () => setPopoverOpen(!popoverOpen)

  const { list = [], filters = [], loading, readAll } = notificationsStore || {}

  const notificationsListIsEmpty = !list.length && !filters.size && !loading && !totalCount

  const notificableType = isSeller ? 'notificableType' : 'payerNotificableType'

  const popoverBodyClasses = classNames({
    'popover-body__no-notifications': notificationsListIsEmpty,
  })

  TAB_TITLES[0].count = counts?.get('elopageNotificationCount') || 0

  const getActiveTab = () => {
    switch (activeTab) {
      case NOTIFICATION_TABS_KEYS.notifications: {
        if (notificationsListIsEmpty) {
          return (
            <div className='notifications-menu__no-notifications'>
              <i className='fas fa-bell-slash' />
              {I18n.t('react.shared.notifications.no_notifications')}
            </div>
          )
        } else {
          return <MyNotifications {...data} />
        }
      }
      default: {
        return null
      }
    }
  }

  return (
    <Fragment>
      <div className='tooltip-menu top-bar__notifications-container'>
        <div
          className='notifications-count__icon-container'
          data-notifications={totalCount > 0}
          id='notifications-menu-id'
          onClick={togglePopover}
        >
          <EloBellIcon />
        </div>

        {children}

        <Popover
          target='notifications-menu-id'
          isOpen={popoverOpen}
          toggle={togglePopover}
          placement='bottom-end'
          className='notifications-menu-tooltip'
          innerClassName='notifications-menu'
          fade={false}
        >
          <PopoverHeader>
            <div className='popover-header__container'>
              <div className='header-title'>{I18n.t('react.shared.notifications_title')}</div>

              <div className='header-icons'>
                <CustomTooltip
                  id='mark-all-as-read'
                  placement={POSITIONS.top}
                  tooltipIcon={() => <i className='fas fa-tasks' id='mark-all-as-read' />}
                  body={I18n.t('react.shared.notifications.mark_all_as_read')}
                  onClick={readAll}
                />
                {isSeller && (
                  <CustomTooltip
                    id='notifications_settings'
                    placement={POSITIONS.top}
                    tooltipIcon={() => <i className='fas fa-sliders-h' id='notifications_settings' />}
                    body={I18n.t('react.shared.notifications.notifications_settings')}
                    onClick={() => window.open('/cabinet/notific_types', '_self')}
                    component
                  />
                )}
              </div>
            </div>
          </PopoverHeader>
          <PopoverBody className={popoverBodyClasses}>
            <TopBarTabs
              tabs={TAB_TITLES}
              activeTab={activeTab}
              setActiveTab={toggleActiveTab}
              defaultTab={NOTIFICATION_TABS_KEYS.notifications}
              filtersList={[...NOTIFICATIONS_FILTER_LIST, notificableType]}
              initStore={notificationsStore}
            />
            {getActiveTab()}
          </PopoverBody>
        </Popover>
      </div>

      {popoverOpen && <div className='tooltip-menu-close' onClick={togglePopover} />}
    </Fragment>
  )
})
