import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  viewbox: PropTypes.string,
  width: PropTypes.number,
}

const defaultProps = {
  className: 'menu__icon',
  viewBox: '0 0 21 17',
  width: 21,
  height: 17,
}

/**
 * SVG Bank Wire Icon
 */
const IconCashout = (props) => {
  const { height, viewBox, width } = props
  return (
    <svg width={width} height={height} viewBox={viewBox}>
      <title>Cashout</title>
      <desc>Created with Sketch.</desc>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Payments-Transfers-Copy' transform='translate(-570.000000, -895.000000)'>
          <g id='Cashout' transform='translate(570.000000, 895.000000)'>
            <rect id='Rectangle' stroke='#00B0DE' strokeWidth='2' x='1' y='1' width='19' height='5' rx='2' />
            <rect id='Rectangle-Copy' fill='#00B0DE' x='4' y='3' width='13' height='14' rx='1' />
            <path
              d='M11.5165877,15 C10.454971,15 9.55924536,14.7168537 8.82938389,14.1505525 C8.09952242,13.5842513 7.59241848,12.8130804 7.30805687,11.8370166 L6,11.8370166 L6,10.441989 L7.06635071,10.441989 C7.05687199,10.3499075 7.0521327,10.2025793 7.0521327,10 C7.0521327,9.77900442 7.05687199,9.61326022 7.06635071,9.50276243 L6,9.50276243 L6,8.10773481 L7.33649289,8.10773481 C7.63981194,7.16850359 8.15165517,6.41574869 8.87203791,5.84944751 C9.59242066,5.28314634 10.4597106,5 11.4739336,5 C12.2985823,5 12.9834096,5.15193218 13.528436,5.4558011 C14.0734624,5.75967003 14.5545003,6.19705055 14.971564,6.7679558 L13.3222749,7.98342541 C13.0379133,7.61509945 12.7511863,7.34116112 12.4620853,7.16160221 C12.1729843,6.9820433 11.8293859,6.89226519 11.4312796,6.89226519 C10.6066309,6.89226519 10.0000019,7.29741768 9.61137441,8.10773481 L12.2417062,8.10773481 L12.2417062,9.50276243 L9.28436019,9.50276243 C9.27488147,9.60405207 9.27014218,9.76979627 9.27014218,10 C9.27014218,10.2025793 9.27488147,10.3499075 9.28436019,10.441989 L12.2417062,10.441989 L12.2417062,11.8370166 L9.5971564,11.8370166 C9.98578393,12.6841663 10.625588,13.1077348 11.5165877,13.1077348 C11.9052152,13.1077348 12.2369655,13.0202587 12.5118483,12.8453039 C12.7867312,12.670349 13.094785,12.3802967 13.436019,11.9751381 L15,13.0939227 C14.5450214,13.7108686 14.0426568,14.1827792 13.492891,14.5096685 C12.9431252,14.8365578 12.284364,15 11.5165877,15 Z'
              id='€'
              fill='#FFFFFF'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

IconCashout.displayName = 'IconCashout'
IconCashout.propTypes = propTypes
IconCashout.defaultProps = defaultProps

export default IconCashout
