import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  viewBox: PropTypes.string,
  width: PropTypes.number,
}

const defaultProps = {
  className: 'menu__icon',
  viewBox: '0 0 39 15',
  width: 39,
  height: 15,
}

/**
 * SVG Bank Wire Icon
 */
const IconBankWireEn = (props) => {
  const { height, viewBox, width } = props
  return (
    <svg width={width} height={height} viewBox={viewBox} preserveAspectRatio='none'>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='Product-creation-Checkout-page2-One-time-payment-Plan-edit'
          transform='translate(-736.000000, -405.000000)'
          fillRule='nonzero'
        >
          <g id='bank-wire' transform='translate(736.000000, 405.000000)'>
            <g id='Group-11' transform='translate(15.464235, 0.000000)' fill='#5E6266'>
              <path
                d='M1.11287869,6.34818157 L1.11287869,0.375051262 L4.33926713,0.375051262 C5.10651804,0.375051262 5.67703794,0.543572181 6.05082685,0.918063108 C6.32625025,1.18020676 6.44428886,1.49852405 6.44428886,1.91046407 C6.44428886,2.50964955 6.10984615,2.97776322 5.46063384,3.23990687 C5.85409585,3.35225414 6.18853855,3.52077506 6.36559645,3.78291871 C6.58200056,4.00761327 6.66069296,4.32593056 6.66069296,4.71914603 C6.66069296,5.24343333 6.44428886,5.65537335 6.03115375,5.93624155 C5.59834554,6.21710974 5.02782564,6.36690612 4.29992093,6.36690612 L1.11287869,6.36690612 L1.11287869,6.34818157 Z M2.78509221,2.75306866 L3.88678583,2.75306866 C4.43763263,2.75306866 4.71305604,2.58454774 4.71305604,2.21005681 C4.71305604,1.85429043 4.43763263,1.68576951 3.92613202,1.68576951 L2.80476531,1.68576951 L2.80476531,2.75306866 L2.78509221,2.75306866 Z M2.78509221,5.01873878 L4.12286302,5.01873878 C4.67370983,5.01873878 4.94913323,4.85021786 4.94913323,4.47572693 C4.94913323,4.11996054 4.67370983,3.93271508 4.10318993,3.93271508 L2.76541911,3.93271508 L2.76541911,5.01873878 L2.78509221,5.01873878 Z'
                id='Shape'
              />
              <path
                d='M8.82473402,6.46052885 C8.33290648,6.46052885 7.93944447,6.34818157 7.64434797,6.08603792 C7.34925147,5.82389427 7.19186666,5.48685244 7.19186666,5.01873878 C7.19186666,4.55062512 7.36892456,4.21358328 7.70336727,3.95143963 C8.03780998,3.68929598 8.49029128,3.5769487 9.10015736,3.5769487 C9.53296562,3.5769487 9.92642762,3.63312234 10.2608703,3.74546962 L10.2608703,3.65184688 C10.2608703,3.16500868 9.92642762,2.90286503 9.27721526,2.90286503 C8.84440708,2.90286503 8.39192578,2.99648776 7.93944447,3.16500868 L7.60500177,2.04153589 C8.21486788,1.81684133 8.88375327,1.68576951 9.55263868,1.68576951 C10.3789089,1.68576951 11.0084481,1.85429043 11.4019102,2.22878136 C11.7363528,2.54709865 11.9134107,3.03393685 11.9134107,3.70802053 L11.9134107,6.34818157 L10.2411972,6.34818157 L10.2411972,5.88006791 C9.84773523,6.25455884 9.39525391,6.46052885 8.82473402,6.46052885 Z M9.43460011,5.44940334 C9.67067733,5.44940334 9.88708143,5.3932297 10.0444662,5.24343333 C10.201851,5.09363696 10.2805434,4.92511604 10.2805434,4.70042149 L10.2805434,4.49445148 C10.1034855,4.40082874 9.84773523,4.38210419 9.61165801,4.38210419 C9.37558078,4.38210419 9.17884982,4.43827784 9.04113811,4.53190057 C8.88375327,4.64424784 8.82473402,4.79404422 8.82473402,4.96256514 C8.82473402,5.11236151 8.88375327,5.24343333 8.98211879,5.33705606 C9.10015736,5.3932297 9.23786907,5.44940334 9.43460011,5.44940334 Z'
                id='Shape'
              />
              <path
                d='M12.7790272,6.34818157 L12.7790272,1.74194315 L14.4905869,1.74194315 L14.4905869,2.37857773 C14.8840489,1.89173953 15.3758764,1.62959588 15.9463963,1.62959588 C16.4578969,1.62959588 16.8513589,1.77939224 17.1661285,2.09770953 C17.4808981,2.41602683 17.5989367,2.82796684 17.5989367,3.35225414 L17.5989367,6.36690612 L15.887377,6.36690612 L15.887377,3.85781689 C15.887377,3.63312234 15.8283577,3.42715233 15.710319,3.27735596 C15.5922805,3.12755958 15.4348957,3.07138595 15.2184916,3.07138595 C15.0020874,3.07138595 14.8250295,3.12755958 14.706991,3.27735596 C14.5889524,3.42715233 14.529933,3.59567325 14.529933,3.85781689 L14.529933,6.34818157 L12.7790272,6.34818157 Z'
                id='Shape'
              />
              <polygon
                id='Shape'
                points='18.4645531 6.34818157 18.4645531 0.112907613 20.1761128 0.112907613 20.1761128 3.27735596 21.4942105 1.74194315 23.4025012 1.74194315 21.7302878 3.59567325 23.4615206 6.34818157 21.5729029 6.34818157 20.589248 4.77531967 20.1564397 5.26215788 20.1564397 6.32945703 18.4645531 6.32945703'
              />
              <polygon
                id='Shape'
                points='2.7850923 14.5869824 0.680070571 8.55767841 2.50966889 8.55767841 3.61136251 12.002995 4.83109472 8.52022932 6.32625034 8.52022932 7.54598256 12.002995 8.6476762 8.55767841 10.4379283 8.55767841 8.33290657 14.5869824 6.81807785 14.5869824 5.55899943 11.1229413 4.31959412 14.5682578 2.7850923 14.5682578'
              />
              <path
                d='M11.3822371,9.47518119 L11.3822371,8.29553476 L13.1528161,8.29553476 L13.1528161,9.47518119 L11.3822371,9.47518119 Z M11.4019102,14.5120842 L11.4019102,9.90584576 L13.1134699,9.90584576 L13.1134699,14.5120842 L11.4019102,14.5120842 Z'
                id='Shape'
              />
              <path
                d='M14.4118946,14.5120842 L14.4118946,9.90584576 L16.1234542,9.90584576 L16.1234542,10.8420731 C16.4185508,10.1492649 16.9497245,9.79349847 17.638283,9.83094757 L17.638283,11.5348813 L17.4808982,11.5348813 C16.5759356,11.5348813 16.084108,12.0591686 16.084108,13.1077432 L16.084108,14.5308087 L14.4118946,14.5120842 Z'
                id='Shape'
              />
              <path
                d='M20.9040176,14.643156 C20.1367667,14.643156 19.5072275,14.4184614 19.0154,13.9690723 C18.5235725,13.5384078 18.2874953,12.9579468 18.2874953,12.2276895 C18.2874953,11.5348813 18.5235725,10.9731449 18.9957269,10.5237558 C19.4482082,10.0556421 20.0384012,9.83094757 20.7663059,9.83094757 C21.5532299,9.83094757 22.163096,10.0556421 22.5959043,10.5612049 C23.0287125,11.0667676 23.2254434,11.628504 23.2254434,12.3587613 C23.2254434,12.5085577 23.2254434,12.620905 23.2254434,12.6770786 L19.9597088,12.6770786 C20.1170936,13.1639168 20.4515363,13.4260605 21.0023831,13.4260605 C21.3958452,13.4260605 21.7302878,13.2762641 22.0647306,12.9579468 L23.0090393,13.7069287 C22.4778656,14.3248387 21.7893072,14.643156 20.9040176,14.643156 Z M19.9203626,11.834474 L21.5925761,11.834474 C21.5532299,11.5723304 21.4745375,11.3663604 21.3171527,11.2352886 C21.1597679,11.0854922 20.98271,11.010594 20.7466328,11.010594 C20.5302287,11.010594 20.3531708,11.1042167 20.195786,11.2352886 C20.0580743,11.3663604 19.9597088,11.5723304 19.9203626,11.834474 Z'
                id='Shape'
              />
            </g>
            <g id='Group' transform='translate(0.000000, 0.142839)' fill='#1A79D1'>
              <path
                d='M13.806901,14.3802732 L0.550702605,14.3802732 C0.334355154,14.3802732 0.157343602,14.2117544 0.157343602,14.0057869 L0.157343602,13.4253331 C0.157343602,13.2193657 0.334355154,13.0508469 0.550702605,13.0508469 L13.826569,13.0508469 C14.0429165,13.0508469 14.219928,13.2193657 14.219928,13.4253331 L14.219928,14.0057869 C14.20026,14.2117544 14.0232485,14.3802732 13.806901,14.3802732 Z'
                id='Shape'
              />
              <g id='Shape' transform='translate(1.770116, 5.617294)'>
                <path d='M2.61583738,6.77820167 L0.531034656,6.77820167 C0.314687203,6.77820167 0.137675652,6.60968285 0.137675652,6.4037154 L0.137675652,0.37448628 C0.137675652,0.168518826 0.314687203,0 0.531034656,0 L2.63550532,0 C2.85185278,0 3.02886433,0.168518826 3.02886433,0.37448628 L3.02886433,6.4037154 C3.00919638,6.60968285 2.85185278,6.77820167 2.61583738,6.77820167 Z' />
                <path d='M6.45108767,6.77820167 L4.36628494,6.77820167 C4.14993749,6.77820167 3.97292594,6.60968285 3.97292594,6.4037154 L3.97292594,0.37448628 C3.97292594,0.168518826 4.14993749,0 4.36628494,0 L6.47075561,0 C6.68710307,0 6.86411462,0.168518826 6.86411462,0.37448628 L6.86411462,6.4037154 C6.84444667,6.60968285 6.66743512,6.77820167 6.45108767,6.77820167 Z' />
                <path d='M10.26667,6.77820167 L8.18186731,6.77820167 C7.96551986,6.77820167 7.78850831,6.60968285 7.78850831,6.4037154 L7.78850831,0.37448628 C7.78850831,0.168518826 7.96551986,0 8.18186731,0 L10.2863379,0 C10.5026854,0 10.6796969,0.168518826 10.6796969,0.37448628 L10.6796969,6.4037154 C10.660029,6.60968285 10.4830175,6.77820167 10.26667,6.77820167 Z' />
              </g>
              <path
                d='M0.216347452,4.17552203 L6.92311847,0.131070198 C7.06079412,0.037448628 7.25747362,0.037448628 7.41481723,0.131070198 L14.1215883,4.17552203 C14.4952793,4.40021379 14.3379357,4.96194321 13.8855728,4.96194321 L0.491698755,4.96194321 C0.0196679502,4.96194321 -0.157343602,4.40021379 0.216347452,4.17552203 Z'
                id='Shape'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

IconBankWireEn.displayName = 'IconBankWireEn'
IconBankWireEn.propTypes = propTypes
IconBankWireEn.defaultProps = defaultProps

export default IconBankWireEn
