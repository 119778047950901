import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  viewbox: PropTypes.string,
  width: PropTypes.number,
}

const defaultProps = {
  className: 'menu__icon',
  viewBox: '0 0 61 20',
  width: 61,
  height: 20,
}

/**
 * SVG Bank Wire Icon
 */
const IconBankWireDe = (props) => {
  const { height, viewBox, width } = props
  return (
    <svg width={width} height={height} viewBox={viewBox} preserveAspectRatio='none'>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Group' fillRule='nonzero'>
          <g fill='#1A79D1' id='Shape'>
            <path d='M18.2337662,19.9480519 L0.727272727,19.9480519 C0.441558442,19.9480519 0.207792208,19.7142857 0.207792208,19.4285714 L0.207792208,18.6233766 C0.207792208,18.3376623 0.441558442,18.1038961 0.727272727,18.1038961 L18.2597403,18.1038961 C18.5454545,18.1038961 18.7792208,18.3376623 18.7792208,18.6233766 L18.7792208,19.4285714 C18.7532468,19.7142857 18.5194805,19.9480519 18.2337662,19.9480519 Z' />
            <g transform='translate(2.337662, 7.792208)'>
              <path d='M3.45454545,9.4025974 L0.701298701,9.4025974 C0.415584416,9.4025974 0.181818182,9.16883117 0.181818182,8.88311688 L0.181818182,0.519480519 C0.181818182,0.233766234 0.415584416,0 0.701298701,0 L3.48051948,0 C3.76623377,0 4,0.233766234 4,0.519480519 L4,8.88311688 C3.97402597,9.16883117 3.76623377,9.4025974 3.45454545,9.4025974 Z' />
              <path d='M8.51948052,9.4025974 L5.76623377,9.4025974 C5.48051948,9.4025974 5.24675325,9.16883117 5.24675325,8.88311688 L5.24675325,0.519480519 C5.24675325,0.233766234 5.48051948,0 5.76623377,0 L8.54545455,0 C8.83116883,0 9.06493506,0.233766234 9.06493506,0.519480519 L9.06493506,8.88311688 C9.03896104,9.16883117 8.80519481,9.4025974 8.51948052,9.4025974 Z' />
              <path d='M13.5584416,9.4025974 L10.8051948,9.4025974 C10.5194805,9.4025974 10.2857143,9.16883117 10.2857143,8.88311688 L10.2857143,0.519480519 C10.2857143,0.233766234 10.5194805,0 10.8051948,0 L13.5844156,0 C13.8701299,0 14.1038961,0.233766234 14.1038961,0.519480519 L14.1038961,8.88311688 C14.0779221,9.16883117 13.8441558,9.4025974 13.5584416,9.4025974 Z' />
            </g>
            <path d='M0.285714286,5.79220779 L9.14285714,0.181818182 C9.32467532,0.0519480519 9.58441558,0.0519480519 9.79220779,0.181818182 L18.6493506,5.79220779 C19.1428571,6.1038961 18.9350649,6.88311688 18.3376623,6.88311688 L0.649350649,6.88311688 C0.025974026,6.88311688 -0.207792208,6.1038961 0.285714286,5.79220779 Z' />
          </g>
          <g transform='translate(20.779221, 3.116883)' fill='#5E6266' id='Shape'>
            <path d='M2.31168831,7.27272727 L0.155844156,0.571428571 L1.81818182,0.571428571 L2.62337662,3.4025974 C2.85714286,4.20779221 3.06493506,4.96103896 3.22077922,5.79220779 L3.24675325,5.79220779 C3.42857143,4.98701299 3.63636364,4.18181818 3.84415584,3.42857143 L4.7012987,0.571428571 L6.31168831,0.571428571 L4.07792208,7.27272727 L2.31168831,7.27272727 Z' />
            <path d='M11.3766234,4.77922078 C11.3766234,6.54545455 10.1038961,7.37662338 8.80519481,7.37662338 C7.37662338,7.37662338 6.28571429,6.44155844 6.28571429,4.88311688 C6.28571429,3.2987013 7.32467532,2.31168831 8.88311688,2.31168831 C10.3896104,2.28571429 11.3766234,3.32467532 11.3766234,4.77922078 Z M7.87012987,4.83116883 C7.87012987,5.66233766 8.20779221,6.28571429 8.85714286,6.28571429 C9.45454545,6.28571429 9.81818182,5.68831169 9.81818182,4.80519481 C9.81818182,4.07792208 9.53246753,3.35064935 8.85714286,3.35064935 C8.15584416,3.37662338 7.87012987,4.12987013 7.87012987,4.83116883 Z' />
            <path d='M12.3116883,4.02597403 C12.3116883,3.2987013 12.3116883,2.83116883 12.2597403,2.41558442 L13.5584416,2.41558442 L13.6103896,3.2987013 L13.6623377,3.2987013 C13.9220779,2.57142857 14.4935065,2.28571429 14.961039,2.28571429 C15.0909091,2.28571429 15.1688312,2.28571429 15.2727273,2.31168831 L15.2727273,3.74025974 C15.1428571,3.71428571 15.038961,3.68831169 14.8571429,3.68831169 C14.3116883,3.68831169 13.9220779,3.97402597 13.8181818,4.44155844 C13.7922078,4.54545455 13.7922078,4.64935065 13.7922078,4.77922078 L13.7922078,7.27272727 L12.2857143,7.27272727 L12.2857143,4.02597403 L12.3116883,4.02597403 Z' />
            <path d='M17.5844156,4.46753247 C17.7142857,4.25974026 17.8441558,4.05194805 17.9480519,3.87012987 L18.9350649,2.41558442 L20.7532468,2.41558442 L19.012987,4.38961039 L20.987013,7.27272727 L19.1168831,7.27272727 L17.9220779,5.27272727 L17.5324675,5.74025974 L17.5324675,7.27272727 L16.025974,7.27272727 L16.025974,0.233766234 L17.5324675,0.233766234 L17.5324675,4.46753247 L17.5844156,4.46753247 Z' />
            <path d='M25.5324675,6.1038961 C25.5324675,6.57142857 25.5584416,7.01298701 25.6103896,7.27272727 L24.2597403,7.27272727 L24.1818182,6.77922078 L24.1558442,6.77922078 C23.8441558,7.16883117 23.3506494,7.37662338 22.7532468,7.37662338 C21.7662338,7.37662338 21.1948052,6.64935065 21.1948052,5.8961039 C21.1948052,4.62337662 22.3376623,4.02597403 24.0519481,4.02597403 L24.0519481,3.97402597 C24.0519481,3.71428571 23.9220779,3.35064935 23.1688312,3.35064935 C22.6753247,3.35064935 22.1558442,3.53246753 21.8181818,3.71428571 L21.5324675,2.75324675 C21.8701299,2.57142857 22.5454545,2.31168831 23.4285714,2.31168831 C25.038961,2.31168831 25.5584416,3.27272727 25.5584416,4.41558442 L25.5584416,6.1038961 L25.5324675,6.1038961 Z M24.0779221,4.98701299 C23.2727273,4.98701299 22.6753247,5.16883117 22.6753247,5.74025974 C22.6753247,6.12987013 22.9350649,6.31168831 23.2727273,6.31168831 C23.6363636,6.31168831 23.9480519,6.05194805 24.0519481,5.76623377 C24.0779221,5.68831169 24.0779221,5.61038961 24.0779221,5.50649351 L24.0779221,4.98701299 Z' />
            <path d='M26.7272727,5.97402597 C27.012987,6.15584416 27.5844156,6.33766234 28.025974,6.33766234 C28.4935065,6.33766234 28.6753247,6.18181818 28.6753247,5.94805195 C28.6753247,5.68831169 28.5194805,5.55844156 27.974026,5.37662338 C26.961039,5.03896104 26.5714286,4.49350649 26.5714286,3.92207792 C26.5714286,3.01298701 27.3506494,2.31168831 28.5454545,2.31168831 C29.1168831,2.31168831 29.6103896,2.44155844 29.8961039,2.5974026 L29.6363636,3.63636364 C29.4285714,3.50649351 28.987013,3.35064935 28.5714286,3.35064935 C28.2077922,3.35064935 28,3.50649351 28,3.74025974 C28,3.97402597 28.1818182,4.1038961 28.7792208,4.31168831 C29.7142857,4.62337662 30.0779221,5.11688312 30.0779221,5.81818182 C30.0779221,6.72727273 29.3766234,7.4025974 27.974026,7.4025974 C27.3506494,7.4025974 26.7792208,7.24675325 26.4155844,7.06493506 L26.7272727,5.97402597 Z' />
            <path d='M31.038961,5.97402597 C31.3246753,6.15584416 31.8961039,6.33766234 32.3376623,6.33766234 C32.8051948,6.33766234 32.987013,6.18181818 32.987013,5.94805195 C32.987013,5.68831169 32.8311688,5.55844156 32.2857143,5.37662338 C31.2727273,5.03896104 30.8831169,4.49350649 30.8831169,3.92207792 C30.8831169,3.01298701 31.6623377,2.31168831 32.8571429,2.31168831 C33.4285714,2.31168831 33.9220779,2.44155844 34.2077922,2.5974026 L33.9480519,3.63636364 C33.7402597,3.50649351 33.2987013,3.35064935 32.8831169,3.35064935 C32.5194805,3.35064935 32.3116883,3.50649351 32.3116883,3.74025974 C32.3116883,3.97402597 32.4935065,4.1038961 33.0909091,4.31168831 C34.025974,4.62337662 34.3896104,5.11688312 34.3896104,5.81818182 C34.3896104,6.72727273 33.6883117,7.4025974 32.2857143,7.4025974 C31.6623377,7.4025974 31.0909091,7.24675325 30.7272727,7.06493506 L31.038961,5.97402597 Z' />
            <path d='M36.5194805,5.32467532 C36.5714286,5.94805195 37.1948052,6.25974026 37.8961039,6.25974026 C38.4155844,6.25974026 38.8311688,6.18181818 39.2467532,6.05194805 L39.4545455,7.06493506 C38.961039,7.27272727 38.3636364,7.37662338 37.6883117,7.37662338 C36.0519481,7.37662338 35.0909091,6.41558442 35.0909091,4.90909091 C35.0909091,3.68831169 35.8441558,2.31168831 37.5324675,2.31168831 C39.0909091,2.31168831 39.6883117,3.53246753 39.6883117,4.72727273 C39.6883117,4.98701299 39.6623377,5.22077922 39.6363636,5.32467532 L36.5194805,5.32467532 Z M38.3116883,4.28571429 C38.3116883,3.92207792 38.1558442,3.2987013 37.4545455,3.2987013 C36.8311688,3.2987013 36.5714286,3.8961039 36.5194805,4.28571429 L38.3116883,4.28571429 Z' />
          </g>
          <g transform='translate(21.298701, 11.688312)' fill='#5E6266' id='Shape'>
            <path d='M0.909090909,1.14285714 L0.909090909,3.87012987 C0.909090909,4.77922078 1.2987013,5.22077922 1.87012987,5.22077922 C2.49350649,5.22077922 2.85714286,4.80519481 2.85714286,3.87012987 L2.85714286,1.14285714 L3.71428571,1.14285714 L3.71428571,3.81818182 C3.71428571,5.27272727 2.96103896,5.8961039 1.84415584,5.8961039 C0.753246753,5.8961039 0.0519480519,5.2987013 0.0519480519,3.81818182 L0.0519480519,1.14285714 L0.909090909,1.14285714 Z M0.805194805,0.415584416 C0.805194805,0.181818182 0.987012987,0 1.22077922,0 C1.45454545,0 1.63636364,0.181818182 1.63636364,0.415584416 C1.63636364,0.649350649 1.48051948,0.831168831 1.22077922,0.831168831 C0.987012987,0.831168831 0.805194805,0.649350649 0.805194805,0.415584416 Z M2.18181818,0.415584416 C2.18181818,0.181818182 2.36363636,0 2.5974026,0 C2.83116883,0 3.01298701,0.181818182 3.01298701,0.415584416 C3.01298701,0.649350649 2.85714286,0.831168831 2.5974026,0.831168831 C2.36363636,0.831168831 2.18181818,0.649350649 2.18181818,0.415584416 Z' />
            <path d='M4.64935065,0.883116883 L5.50649351,0.883116883 L5.50649351,2.90909091 L5.53246753,2.90909091 C5.74025974,2.57142857 6.1038961,2.36363636 6.62337662,2.36363636 C7.45454545,2.36363636 8.05194805,3.03896104 8.02597403,4.07792208 C8.02597403,5.2987013 7.24675325,5.8961039 6.49350649,5.8961039 C6.05194805,5.8961039 5.66233766,5.74025974 5.42857143,5.32467532 L5.4025974,5.32467532 L5.35064935,5.84415584 L4.62337662,5.84415584 C4.64935065,5.61038961 4.64935065,5.24675325 4.64935065,4.88311688 L4.64935065,0.883116883 Z M5.50649351,4.41558442 C5.50649351,4.49350649 5.50649351,4.54545455 5.53246753,4.62337662 C5.61038961,4.96103896 5.92207792,5.22077922 6.28571429,5.22077922 C6.83116883,5.22077922 7.14285714,4.80519481 7.14285714,4.1038961 C7.14285714,3.50649351 6.85714286,3.01298701 6.28571429,3.01298701 C5.94805195,3.01298701 5.61038961,3.27272727 5.53246753,3.63636364 C5.50649351,3.68831169 5.50649351,3.76623377 5.50649351,3.87012987 L5.50649351,4.41558442 Z' />
            <path d='M9.32467532,4.36363636 C9.35064935,4.98701299 9.81818182,5.24675325 10.3636364,5.24675325 C10.7532468,5.24675325 11.038961,5.19480519 11.2987013,5.09090909 L11.4285714,5.66233766 C11.1428571,5.79220779 10.7272727,5.87012987 10.2597403,5.87012987 C9.16883117,5.87012987 8.51948052,5.19480519 8.51948052,4.15584416 C8.51948052,3.22077922 9.09090909,2.33766234 10.1818182,2.33766234 C11.2727273,2.33766234 11.6363636,3.24675325 11.6363636,3.97402597 C11.6363636,4.12987013 11.6103896,4.25974026 11.6103896,4.33766234 L9.32467532,4.33766234 L9.32467532,4.36363636 Z M10.8051948,3.76623377 C10.8051948,3.45454545 10.6753247,2.93506494 10.1038961,2.93506494 C9.58441558,2.93506494 9.35064935,3.4025974 9.32467532,3.76623377 L10.8051948,3.76623377 Z' />
            <path d='M12.2857143,3.53246753 C12.2857143,3.06493506 12.2857143,2.75324675 12.2597403,2.44155844 L12.987013,2.44155844 L13.012987,3.09090909 L13.038961,3.09090909 C13.1948052,2.62337662 13.6103896,2.36363636 13.974026,2.36363636 C14.0519481,2.36363636 14.1038961,2.36363636 14.1818182,2.38961039 L14.1818182,3.19480519 C14.1038961,3.16883117 14.025974,3.16883117 13.9220779,3.16883117 C13.5064935,3.16883117 13.2467532,3.42857143 13.1688312,3.81818182 C13.1428571,3.8961039 13.1428571,3.97402597 13.1428571,4.07792208 L13.1428571,5.84415584 L12.2857143,5.84415584 L12.2857143,3.53246753 Z' />
            <path d='M15.4285714,2.44155844 L15.7662338,3.97402597 C15.8441558,4.33766234 15.9220779,4.7012987 16,5.06493506 L16.025974,5.06493506 C16.1038961,4.7012987 16.2077922,4.31168831 16.2857143,3.97402597 L16.7272727,2.44155844 L17.4285714,2.44155844 L17.8441558,3.94805195 C17.9480519,4.33766234 18.025974,4.7012987 18.1038961,5.09090909 L18.1298701,5.09090909 C18.1818182,4.72727273 18.2597403,4.36363636 18.3636364,3.94805195 L18.7272727,2.44155844 L19.5584416,2.44155844 L18.4935065,5.81818182 L17.7142857,5.81818182 L17.2987013,4.44155844 C17.1948052,4.07792208 17.1168831,3.76623377 17.038961,3.35064935 L17.012987,3.35064935 C16.9350649,3.76623377 16.8571429,4.1038961 16.7532468,4.44155844 L16.3116883,5.81818182 L15.5064935,5.81818182 L14.4935065,2.44155844 L15.4285714,2.44155844 Z' />
            <path d='M20.6493506,4.36363636 C20.6753247,4.98701299 21.1428571,5.24675325 21.6883117,5.24675325 C22.0779221,5.24675325 22.3636364,5.19480519 22.6233766,5.09090909 L22.7532468,5.66233766 C22.4675325,5.79220779 22.0519481,5.87012987 21.5844156,5.87012987 C20.4935065,5.87012987 19.8441558,5.19480519 19.8441558,4.15584416 C19.8441558,3.22077922 20.4155844,2.33766234 21.5064935,2.33766234 C22.5974026,2.33766234 22.961039,3.24675325 22.961039,3.97402597 C22.961039,4.12987013 22.9350649,4.25974026 22.9350649,4.33766234 L20.6493506,4.33766234 L20.6493506,4.36363636 Z M22.1298701,3.76623377 C22.1298701,3.45454545 22,2.93506494 21.4285714,2.93506494 C20.9090909,2.93506494 20.6753247,3.4025974 20.6493506,3.76623377 L22.1298701,3.76623377 Z' />
            <path d='M24.5194805,1.48051948 C24.5194805,1.74025974 24.3376623,1.94805195 24.025974,1.94805195 C23.7402597,1.94805195 23.5584416,1.74025974 23.5584416,1.48051948 C23.5584416,1.22077922 23.7402597,1.01298701 24.025974,1.01298701 C24.3376623,1.01298701 24.5194805,1.22077922 24.5194805,1.48051948 Z M23.6103896,5.81818182 L23.6103896,2.44155844 L24.4675325,2.44155844 L24.4675325,5.81818182 L23.6103896,5.81818182 Z' />
            <path d='M25.3506494,5.01298701 C25.5324675,5.14285714 25.8961039,5.27272727 26.2077922,5.27272727 C26.5714286,5.27272727 26.7532468,5.11688312 26.7532468,4.88311688 C26.7532468,4.64935065 26.6233766,4.54545455 26.2077922,4.38961039 C25.5584416,4.15584416 25.2727273,3.81818182 25.2727273,3.4025974 C25.2727273,2.80519481 25.7662338,2.33766234 26.5454545,2.33766234 C26.9090909,2.33766234 27.2467532,2.41558442 27.4545455,2.54545455 L27.2727273,3.14285714 C27.1428571,3.06493506 26.8571429,2.96103896 26.5454545,2.96103896 C26.2337662,2.96103896 26.0779221,3.11688312 26.0779221,3.32467532 C26.0779221,3.53246753 26.2337662,3.63636364 26.6753247,3.79220779 C27.2987013,4 27.5844156,4.31168831 27.5844156,4.83116883 C27.5844156,5.45454545 27.1168831,5.8961039 26.2077922,5.8961039 C25.7922078,5.8961039 25.4285714,5.79220779 25.1688312,5.66233766 L25.3506494,5.01298701 Z' />
            <path d='M31.3246753,4.80519481 C31.3246753,5.22077922 31.3506494,5.53246753 31.3506494,5.81818182 L30.5974026,5.81818182 L30.5454545,5.2987013 L30.5194805,5.2987013 C30.3636364,5.53246753 30.0519481,5.87012987 29.4285714,5.87012987 C28.8051948,5.87012987 28.2597403,5.50649351 28.2597403,4.38961039 L28.2597403,2.41558442 L29.1168831,2.41558442 L29.1168831,4.25974026 C29.1168831,4.83116883 29.2987013,5.19480519 29.7402597,5.19480519 C30.0779221,5.19480519 30.3116883,4.96103896 30.3896104,4.72727273 C30.4155844,4.64935065 30.4415584,4.57142857 30.4415584,4.46753247 L30.4415584,2.41558442 L31.2987013,2.41558442 L31.2987013,4.80519481 L31.3246753,4.80519481 Z' />
            <path d='M32.2337662,3.45454545 C32.2337662,3.06493506 32.2077922,2.72727273 32.2077922,2.44155844 L32.961039,2.44155844 L33.012987,2.96103896 L33.038961,2.96103896 C33.1948052,2.7012987 33.5584416,2.38961039 34.1298701,2.38961039 C34.7272727,2.38961039 35.3246753,2.77922078 35.3246753,3.84415584 L35.3246753,5.84415584 L34.4675325,5.84415584 L34.4675325,3.8961039 C34.4675325,3.4025974 34.2857143,3.03896104 33.8181818,3.03896104 C33.4805195,3.03896104 33.2467532,3.27272727 33.1428571,3.53246753 C33.1168831,3.61038961 33.0909091,3.71428571 33.0909091,3.79220779 L33.0909091,5.79220779 L32.2337662,5.79220779 L32.2337662,3.45454545 Z' />
            <path d='M39.2727273,5.32467532 C39.2727273,6.02597403 39.1168831,6.54545455 38.7792208,6.85714286 C38.4415584,7.16883117 37.9480519,7.27272727 37.4805195,7.27272727 C37.038961,7.27272727 36.5974026,7.16883117 36.3116883,7.01298701 L36.4935065,6.36363636 C36.7012987,6.49350649 37.0649351,6.62337662 37.4805195,6.62337662 C38.025974,6.62337662 38.4415584,6.33766234 38.4415584,5.61038961 L38.4415584,5.32467532 L38.4155844,5.32467532 C38.2337662,5.63636364 37.8701299,5.81818182 37.4285714,5.81818182 C36.5974026,5.81818182 36,5.11688312 36,4.15584416 C36,3.03896104 36.7272727,2.36363636 37.5324675,2.36363636 C38.0519481,2.36363636 38.3636364,2.62337662 38.5194805,2.88311688 L38.5454545,2.88311688 L38.5714286,2.44155844 L39.3246753,2.44155844 C39.2987013,2.67532468 39.2987013,2.96103896 39.2987013,3.4025974 L39.2987013,5.32467532 L39.2727273,5.32467532 Z M38.4155844,3.79220779 C38.4155844,3.71428571 38.4155844,3.63636364 38.3896104,3.55844156 C38.3116883,3.24675325 38.0519481,3.01298701 37.6883117,3.01298701 C37.2207792,3.01298701 36.8571429,3.42857143 36.8571429,4.12987013 C36.8571429,4.7012987 37.1428571,5.16883117 37.6883117,5.16883117 C38,5.16883117 38.2857143,4.96103896 38.3896104,4.64935065 C38.4155844,4.57142857 38.4155844,4.44155844 38.4155844,4.33766234 L38.4155844,3.79220779 Z' />
          </g>
        </g>
      </g>
    </svg>
  )
}

IconBankWireDe.displayName = 'IconBankWireDe'
IconBankWireDe.propTypes = propTypes
IconBankWireDe.defaultProps = defaultProps

export default IconBankWireDe
