import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  viewbox: PropTypes.string,
  width: PropTypes.number,
}

const defaultProps = {
  className: 'menu__icon',
  viewbox: '0 0 19 16',
  width: 19,
  height: 16,
}

/**
 * SVG Paypal Icon
 */
const IconPayLater = () => (
  <svg width='20px' height='17px' viewBox='0 0 20 17'>
    <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Recurring-payments-Add-action' transform='translate(-691.000000, -691.000000)'>
        <g id='PayLater' transform='translate(691.000000, 691.000000)'>
          <g>
            <path
              d='M19.6389511,3.50361956 L19.6389511,1.5609908 C19.6389511,0.70245659 18.931949,1.95121411e-05 18.0678349,1.95121411e-05 L1.57111608,1.95121411e-05 C0.707002239,1.95121411e-05 4.01456646e-13,0.70245659 4.01456646e-13,1.5609908 L4.01456646e-13,3.50361956 L19.6389511,3.50361956 Z'
              id='Fill-1'
              fill='#1E84D7'
            />
            <path
              d='M7.34830632,10.7801653 C7.34830632,8.63675657 8.33968062,6.71910332 9.89037213,5.45491171 L8.39328607e-13,5.45491171 L8.39328607e-13,11.1048472 C8.39328607e-13,11.9633814 0.707002239,12.6658184 1.57111608,12.6658184 L7.61461052,12.6658184 C7.44237693,12.0662105 7.34830632,11.4342122 7.34830632,10.7801653'
              id='Fill-3'
              fill='#1E84D7'
            />
            <path
              d='M16.6292428,11.395832 L14.2497875,11.395832 C13.9206386,11.395832 13.66219,11.1390522 13.66219,10.8120287 L13.66219,7.82335405 C13.66219,7.49652568 13.9206386,7.23974591 14.2497875,7.23974591 C14.5787399,7.23974591 14.8371885,7.49652568 14.8371885,7.82335405 L14.8371885,10.2284205 L16.6292428,10.2284205 C16.9583917,10.2284205 17.2168402,10.4852003 17.2168402,10.8120287 C17.2168402,11.1390522 16.9583917,11.395832 16.6292428,11.395832 M18.0689742,7.0145758 C15.9791935,4.93828887 12.5789055,4.93828887 10.4891247,7.0145758 C8.39954032,9.09066759 8.39934392,12.4691949 10.4891247,14.5456769 C12.5789055,16.6217686 15.9793899,16.6217686 18.0689742,14.545872 C20.1589515,12.4691949 20.1589515,9.09086273 18.0689742,7.0145758'
              id='Fill-5'
              fill='#39AA75'
              fillRule='nonzero'
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

IconPayLater.displayName = 'IconPayLater'
IconPayLater.propTypes = propTypes
IconPayLater.defaultProps = defaultProps

export default IconPayLater
