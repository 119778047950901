import React, { Fragment, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import classNames from 'classnames'

import {
  NOTIFIC_TYPES_KEYS,
  NOTIFIC_TYPES_ORDER_KEYS,
  NOTIFIC_TYPES_TRANSACTIONS_KEYS,
} from 'constants/notifications.constants'
import { ACTIVE_PROFILES } from 'constants/profile.constants'
import { PAYMENT_STATES } from 'constants/orders.constants'
import { PAGINATION_KEYS, NOTIFICATION_ITEMS_PER_PAGE } from 'constants/pagination.constants'
import { PAYMENT_FORMS } from 'constants/paymentSetting.constants'
import { STATUSES } from 'constants/general.constants'
import { DATE_FORMATS } from '@elo-kit/constants/dateTime.constants'
import { LOCALES } from '@elo-kit/constants/locales.constants'
import { SIZES } from '@elo-kit/constants/general.constants'

import { createId } from '@elo-kit/utils/general.utils'
import { formatDateTime } from '@elo-kit/utils/dateTime.utils'

import { capitalize } from 'utils/nameStyle.utils'

import { Ellipsis } from '@elo-kit/components/ellipsis/Ellipsis'
import { LoadingSpinner } from '@elo-kit/components/loading-spinner/LoadingSpinner'
import { EloButton } from '@elo-kit/components/elo-button/EloButton'
import EloTable from 'ui/elo-table/EloTable'
import IconSepa from 'ui/icons/IconSepa'
import IconBankWireEn from 'ui/icons/IconBankWireEn'
import IconBankWireDe from 'ui/icons/IconBankWireDe'
import IconPaypal from 'ui/icons/IconPaypal'
import IconSofort from 'ui/icons/IconSofort'
import IconPayLater from 'ui/icons/IconPayLater'
import IconCashout from 'ui/icons/IconCashout'

import CreditCardDeUrl from '../../containers/app/assets/svg/Kreditkarte.svg'
import CreditCardEnUrl from '../../containers//app/assets/svg/CreditCard.svg'
import PayLaterDe from '../../containers/app/assets/svg/Kauf auf Rechnung.svg'

import './top-bar.scss'

const quizStatusesIcons = {
  failed: <i className='fas fa-times-circle' />,
  completed: <i className='fas fa-check-circle' />,
  waiting: <i className='fas fa-question-circle' />,
  skipped: <i className='fas fa-minus-circle' />,
  retrying: <i className='fas fa-undo-alt' />,
}

const locales = {
  day_income: I18n.t('react.shared.notifications.last_day_revenue'),
  week_income: I18n.t('react.shared.notifications.last_week_revenue'),
  month_income: I18n.t('react.shared.notifications.last_month_revenue'),
}

const profileTypeUrls = ({ productId, productSlug, lessonId, notificableId, idForSeller, sellerUsername }) => ({
  seller: {
    commentUrl: `/cabinet/products/${productId}/course-preview?lesson_id=${lessonId}&comment_id=${notificableId}`,
    quizUrl: `/cabinet/products/${productId}/course-preview?lesson_id=${lessonId}`,
    transferUrl: `/cabinet/transfers/${idForSeller}`,
    orderUrl: `/cabinet/orders/${notificableId}`,
  },
  payer: {
    commentUrl: `/payer/s/${sellerUsername}/courses/${productSlug}?lesson_id=${lessonId}&comment_id=${notificableId}`,
    quizUrl: `/payer/s/${sellerUsername}/courses/${productSlug}?lesson_id=${lessonId}`,
  },
})

const localeEn = I18n.locale === LOCALES.en

const paymethodsIcons = {
  card: <img src={localeEn ? CreditCardEnUrl : CreditCardDeUrl} alt={PAYMENT_FORMS.card} />,
  bank_wire: localeEn ? <IconBankWireEn /> : <IconBankWireDe />,
  sepa: <IconSepa />,
  sofort: <IconSofort height={30} viewBox='0 0 50 25' />,
  paypal: <IconPaypal />,
  pay_later: localeEn ? <IconPayLater /> : <img src={PayLaterDe} alt={PAYMENT_FORMS.payLater} />,
  bank_account: <IconCashout />,
  free: <i className='fas fa-gift elo-icon--free' />,
}

const getNotificationData = ({ currenciesStore, notification, profileType }) => {
  const { notificable, sender, seller, replied, customersData, notificType = {}, notificableType } = notification
  const { id: notificableId, idForSeller, lesson = {}, form, status, paymentState, paymentForm } = notificable || {}
  const { id: lessonId, name: lessonName, product } = lesson
  const { id: productId, slug: productSlug } = product || {}
  const { username: sellerUsername } = seller || {}
  const { userProfile } = sender || {}
  const { firstName = '', lastName = '', publicName } = userProfile || {}
  const senderFullName = `${firstName} ${lastName}`
  const { commentUrl, quizUrl, transferUrl, orderUrl } = profileTypeUrls({
    productId,
    productSlug,
    lessonId,
    notificableId,
    idForSeller,
    sellerUsername,
  })[profileType]

  const { revenue, uniqCustomersCount = 0 } = customersData || {}
  const Icon = paymethodsIcons[form || paymentForm]
  const isSeller = profileType === ACTIVE_PROFILES.seller
  const notificTypeKeySplitted = (notificType.key || '').split('_')
  const incomeType = notificTypeKeySplitted[1] === 'income' && capitalize(notificTypeKeySplitted[1])
  const isFree = form === 'free'
  const isOrderCancelled =
    paymentState === PAYMENT_STATES.paymentCanceled && notificType.key === NOTIFIC_TYPES_KEYS.orderCanceled

  const incomeRevenue = Object.entries(revenue || {}).map(
    ([key, value]) => `${currenciesStore.convertToPrice(value, key)} `
  )

  const transferIconClasses = classNames('fas fa-exchange-alt', {
    'elo-icon--free': isFree,
  })

  const orderIconClasses = classNames('fas fa-clipboard-list', {
    'elo-icon--free': !isOrderCancelled,
    'elo-icon__canceled': isOrderCancelled,
  })
  const statusLabels = {
    completed: I18n.t('react.shared.notifications.statuses.seller.completed'),
    failed: I18n.t('react.shared.notifications.statuses.seller.failed'),
    in_progress: I18n.t('react.shared.notifications.statuses.seller.in_progress'),
    retrying: I18n.t('react.shared.notifications.statuses.seller.retrying'),
    skipped: I18n.t('react.shared.notifications.statuses.seller.skipped'),
    waiting: I18n.t('react.shared.notifications.statuses.seller.waiting'),
  }
  const notificTypeLabels = {
    quiz_attempt: I18n.t('react.shared.notifications.quiz_attempt'),
    comment: I18n.t('react.shared.notifications.comment'),
    free_transaction: I18n.t('react.shared.notifications.free_transaction'),
    paid_transaction: I18n.t('react.shared.notifications.paid_transaction'),
    day_income: I18n.t('react.shared.notifications.day_income'),
    week_income: I18n.t('react.shared.notifications.week_income'),
    month_income: I18n.t('react.shared.notifications.month_income'),
    order_created: I18n.t('react.shared.notifications.order_created'),
    order_canceled: I18n.t('react.shared.notifications.order_canceled'),
    community_new_post_for_review: I18n.t('react.shared.notifications.community_new_post_for_review'),
  }
  const data = {
    Comment: {
      icon: <i className='fas fa-comment' />,
      body: (
        <div className='notification-comment'>
          <div>{publicName || senderFullName}</div>
          <div className='grey mx-2 notification-text'>
            {replied
              ? I18n.t('react.shared.notifications.replied_to')
              : I18n.t('react.shared.notifications.commented_on')}
          </div>
          <a className='d-inline-block link notification-link' href={commentUrl} target={isSeller ? '_blank' : '_self'}>
            <Ellipsis maxLength={40}>{lessonName}</Ellipsis>
          </a>
        </div>
      ),
      type: NOTIFIC_TYPES_KEYS.comment,
      commentUrl: commentUrl,
    },
    QuizAttempt: {
      icon: quizStatusesIcons[status],
      body: (
        <Fragment>
          {senderFullName}
          <span className='grey mx-2'>
            {isSeller ? (
              <Fragment>
                <span className='mr-2 grey notification-text'>{statusLabels[status]?.toLowerCase()}</span>

                {I18n.t('react.shared.notifications.the_quiz')}
              </Fragment>
            ) : (
              <div className='d-inline-block'>
                {status === 'completed'
                  ? I18n.t('react.shared.notifications.statuses.payer.completed')
                  : I18n.t('react.shared.notifications.statuses.payer.failed')}
              </div>
            )}
          </span>
          <a className='d-inline-block link notification-link' href={quizUrl} target={isSeller ? '_blank' : '_self'}>
            <Ellipsis maxLength={status === STATUSES.waiting ? 35 : 40}>{lessonName}</Ellipsis>
          </a>
        </Fragment>
      ),
      type: NOTIFIC_TYPES_KEYS.quizAttempt,
      notificableId: notificableId,
      quizUrl: quizUrl,
    },
    Transfer: {
      icon: <i className={transferIconClasses} />,
      body: (
        <Fragment>
          {senderFullName}
          <a className='d-inline-block notification-link' href={transferUrl} target={isSeller ? '_blank' : '_self'}>
            {idForSeller}
          </a>
          <span className='grey mx-2 notification-text'>{notificTypeLabels[notificType.key]}</span>
          {Icon}
        </Fragment>
      ),
      type: notificType.key,
      transferUrl: transferUrl,
    },
    Income: {
      icon: <i className='fas fa-money-bill-wave elo-icon--free' />,
      body: (
        <Fragment>
          {senderFullName}
          <span className='d-inline-block mr-2 grey notification-text'>
            {`${locales[notificType.key]} ${incomeRevenue.join(' ') || currenciesStore.convertToPrice(0)}`}
          </span>
          <i
            className='d-inline-block grey'
            dangerouslySetInnerHTML={{
              __html: I18n.t('react.shared.notifications.customers_created', { customers: uniqCustomersCount }),
            }}
          />
        </Fragment>
      ),
    },
    Order: {
      icon: <i className={orderIconClasses} />,
      body: (
        <Fragment>
          <a className='d-inline-block notification-link' href={orderUrl} target={isSeller ? '_blank' : '_self'}>
            {notificableId}
          </a>
          <span className='grey mx-2 notification-text'>{notificTypeLabels[notificType.key]}</span>
          {Icon}
        </Fragment>
      ),
      type: notificType.key,
      orderUrl: orderUrl,
    },
  }

  return data[incomeType || notificableType]
}

interface MyNotificationsProps {
  currenciesStore?: any
  notificationsStore?: any
  profileType?: string
}

export const MyNotifications: React.FC<MyNotificationsProps> = inject('currenciesStore')(
  observer(({ currenciesStore, notificationsStore, profileType }) => {
    const [loadingList, setLoadingList] = useState<number[]>([])
    const { list } = notificationsStore

    const toggleNotification = (method) => async (id) => {
      const apiCall = notificationsStore[method]
      setLoadingList([id])
      await apiCall(id, loadingList.length)
      setLoadingList([])
    }

    useEffect(() => {
      notificationsStore.handlePaginationChange(PAGINATION_KEYS.per, NOTIFICATION_ITEMS_PER_PAGE)
      return () => notificationsStore.resetFilters()
    }, [])

    const readNotification = toggleNotification('read')
    const unreadNotification = toggleNotification('unread')

    const getTableBody = (list) =>
      list.map((notification, index) => {
        const { createdAt, updatedAt, id, read } = notification || {}

        const { body, icon, type, notificableId, commentUrl, transferUrl, orderUrl, quizUrl } =
          getNotificationData({
            currenciesStore,
            notification,
            profileType,
          }) || {}

        const isSeller = profileType === ACTIVE_PROFILES.seller
        const [isQuizAttempt, isComment] = [NOTIFIC_TYPES_KEYS.quizAttempt, NOTIFIC_TYPES_KEYS.comment].map(
          (notificationType) => notificationType === type
        )
        const isTransfer = NOTIFIC_TYPES_TRANSACTIONS_KEYS.includes(type)
        const isOrder = NOTIFIC_TYPES_ORDER_KEYS.includes(type)
        const isReviewableType = isQuizAttempt || isComment || isTransfer || isOrder

        const reviewContentButton = (contentId, contentUrl) => (
          <EloButton
            id={contentId}
            className='reviewable-content'
            to={contentUrl}
            target={isSeller ? '_blank' : '_self'}
          >
            <i className='fas fa-search' />
            {I18n.t('react.shared.button.review')}
          </EloButton>
        )

        return loadingList.includes(id) ? (
          <tr key={createId(id, index)} className='table__row table__row--hovered'>
            <td className='spinner-column'>
              <LoadingSpinner size={SIZES.small} />
            </td>
          </tr>
        ) : (
          <tr className={classNames('table__row table__row--hovered', { read })} key={createId(id, index)}>
            <div>
              <td className='elo-table__title table__column icon'>
                <div className='icon__container'>{icon}</div>
              </td>
              <td className='elo-table__title table__column description'>
                <div className='column-content'>
                  <div className='body-wrapper'>{body}</div>
                  {read ? (
                    <Fragment>
                      <div className='notification-subtitle'>{formatDateTime(updatedAt, DATE_FORMATS.DDMMYYHHmm)}</div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className='notification-subtitle'>{formatDateTime(createdAt, DATE_FORMATS.DDMMYYHHmm)}</div>
                    </Fragment>
                  )}
                </div>
              </td>
              <td className='elo-table__title table__column button-column'>
                {read ? (
                  <Fragment>
                    <EloButton
                      onClick={() => unreadNotification(id)}
                      className='action-button'
                      outlined={isReviewableType}
                    >
                      {I18n.t('react.shared.notifications.mark_as_unread')}
                    </EloButton>
                    {isQuizAttempt && isSeller
                      ? reviewContentButton('review-quiz', `/cabinet/courses/quiz_attempts/${notificableId}`)
                      : reviewContentButton(type, quizUrl)}
                    {isComment && reviewContentButton(type, commentUrl)}
                    {isTransfer && reviewContentButton(type, transferUrl)}
                    {isOrder && reviewContentButton(type, orderUrl)}
                  </Fragment>
                ) : (
                  <Fragment>
                    <EloButton
                      onClick={() => readNotification(id)}
                      className='action-button'
                      outlined={isReviewableType}
                    >
                      {I18n.t('react.shared.notifications.mark_as_read')}
                    </EloButton>
                    {isQuizAttempt && isSeller
                      ? reviewContentButton('review-quiz', `/cabinet/courses/quiz_attempts/${notificableId}`)
                      : reviewContentButton(type, quizUrl)}
                    {isComment && reviewContentButton(type, commentUrl)}
                    {isTransfer && reviewContentButton(type, transferUrl)}
                    {isOrder && reviewContentButton(type, orderUrl)}
                  </Fragment>
                )}
              </td>
            </div>
          </tr>
        )
      })

    return (
      <Fragment>
        <div className='content'>
          <EloTable
            initStore={notificationsStore}
            search={false}
            tableWithoutMargin
            showItemsPerPageField={false}
            pageToGoField={false}
            itemsNumberField={false}
            performScroll={false}
            scrollIntoView
          >
            <EloTable.Body>{getTableBody(list)}</EloTable.Body>
          </EloTable>
        </div>
      </Fragment>
    )
  })
)
